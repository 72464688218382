.title {
  text-align: center;
  color: rgb(255, 255, 255);
  font-family: "Poppins", sans-serif;
}

.title:hover {
  cursor: pointer;
}

.header {
  --background: rgb(1,16,66);
  --border-color: rgb(1,16,66);
  --padding-start: 3%;
  --padding-end: 3%;
  --border-style: none;
}

.mainContent {
  --background: url("../assets/background.png");
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 203, 253);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 203, 253);
}
