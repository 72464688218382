.projectsPage {
    height: auto;
    margin: 0 auto 0 auto;
    padding-top: 10vh;
    width: 90%;
}

.projectCard {
    max-width: 500px;
    min-width: 300px;
}

.projectContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto 0 auto;
    justify-content: center;
    gap: 30px;
}